import Typed from "typed.js";
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "../inicio/Inicio.css";
import { servicios } from "../../data/servicios";
import { escritos } from "../../data/escritos";
import { trabajos } from "../../data/trabajos";

export const Inicio = () => {
  const meImages = require.context("../../assets/images/marca/", true);
  const companyImages = require.context("../../assets/images/empresas/", true);
  const proyectImages = require.context(
    "../../assets/images/portafolio/",
    true
  );
  const articleImages = require.context("../../assets/images/blog/", true);

  const el = useRef(null);

  useEffect(() => {
    const escribiendo = new Typed(el.current, {
      strings: [
        "mi nombre es cristian camilo alzate ramirez",
        "soy desarrollador",
        "de software emprendedor",
        "creo páginas y aplicaciones web",
        "bienvenido(a) a cristiancamiloalzateramirezweb.",
      ],
      loop: true,
      typeSpeed: 100,
      backSpeed: 80,
      startDelay: 1000,
      backDelay: 1500,
    });

    return () => {
      escribiendo.destroy();
    };
  }, []);

  return (
    <>
      <section className="inicio">
        <div className="imagen-background">
          <div className="slider-typography">
            <div className="slider-main">
              <h1>
                <span className="headline">HOLA,</span>
                <span ref={el} className="multiText"></span>
              </h1>
            </div>
          </div>
        </div>
      </section>
      <section className="inicio-sobremi">
        <div className="container">
          <span className="title">desarrollo páginas y aplicaciones web</span>
          <h2>
            mi nombre es cristian camilo alzate ramirez, desarrollador de
            software emprendedor.
          </h2>
          <p>
            <strong>cristiancamiloalzateramirezweb</strong>, un reflejo de mi
            sueño de crecer como profesional en el mundo del desarrollo de
            software. Desde entonces, me he dedicado a especializarme en el
            desarrollo de páginas y aplicaciones web, colaborando con dos
            empresas y fundando mi propio emprendimiento. La programación, la
            tecnología, la informática y el mundo de Internet me apasionan
            profundamente. Disfruto creando cosas nuevas, aprendiendo y
            compartiendo conocimientos con los demás. Cada día, dedicarme a
            estas actividades me brinda una gran felicidad y satisfacción en mi
            vida.
          </p>
          <p>
            Mi objetivo es impactar de forma positiva tanto en clientes como en
            colegas, aportando mis habilidades y experiencias generando
            herramientas como:
          </p>
          <ul>
            <li>
              <a href="https://www.youtube.com/@cristiancamiloalzateramirezweb/">
                <i className="fa-solid fa-video"></i>
                <p>Tutoriales</p>
              </a>
            </li>
            <li>
              <Link to="/blog">
                <i className="fa-solid fa-book"></i>
                <p>Articulos</p>
              </Link>
            </li>
            <li>
              <a href="https://open.spotify.com/show/3xHFVNTx4ihEvGvqerclhM">
                <i className="fa-solid fa-microphone"></i>
                <p>Podcast</p>
              </a>
            </li>
            <li>
              <Link to="/portafolio">
                <i className="fa-solid fa-laptop-code"></i>
                <p>Proyectos</p>
              </Link>
            </li>
          </ul>
        </div>
      </section>
      <section className="inicio-miservicios">
        <div className="container">
          <h3>Mis Servicios</h3>
          <p>
            Aquí encontrarás mis servicios que se ajustan a tus necesidades,
            ofreciéndote soluciones efectivas y eficientes para ayudarte a
            alcanzar tus propósitos.
          </p>
          <div className="list-services">
            {servicios.map((servicio) => {
              return (
                <div className="service">
                  <div className="icon">
                    <i className={servicio.icon}></i>
                  </div>
                  <Link to={"/servicio/" + servicio.id}>{servicio.name}</Link>
                  <p>{servicio.intro}</p>
                </div>
              );
            })}
          </div>
          <div className="button">
            <Link to="/servicios">Ver Servicios <i class="fa-solid fa-arrow-right"></i></Link>
          </div>
        </div>
      </section>
      <section className="inicio-miportafolio">
        <div className="container">
          <h3>Últimos Proyectos</h3>
          <p>
            A continuación, te presento algunos de los proyectos que he
            desarrollado recientemente.
          </p>
          <div className="list-proyects">
            {trabajos.slice(0, 6).map((trabajo) => {
              return (
                <div className="proyect">
                  <div className="cover">
                    <Link to={"/proyecto/" + trabajo.id}>
                      <video
                        autoplay="autoplay"
                        loop="loop"
                        muted="muted"
                        src={proyectImages(`./${trabajo.id}.mp4`)}
                        type="video/mp4"
                      ></video>
                    </Link>
                  </div>
                  <div className="info">
                    <span className="date">
                      Realizado por: {trabajo.creator} | {trabajo.date}
                    </span>
                    <Link to={"/proyecto/" + trabajo.id}>{trabajo.name}</Link>
                    <p>{trabajo.introduction}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="metodologia">
            <div className="card">
              <div className="top">
                <span className="number">01</span>
                <span className="title">Estrategia</span>
                <span className="line"></span>
              </div>
              <div className="bottom">
                <span className="icon">
                  <i className="fa-solid fa-compass-drafting"></i>
                </span>
                <p>
                  Creo estrategias personalizadas que incrementen el valor de
                  los productos y/o servicios de mis clientes que ayuden a
                  alcanzar los objetivos propuestos.
                </p>
              </div>
            </div>
            <div className="card">
              <div className="top">
                <span className="number">02</span>
                <sapn className="title">Planeación</sapn>
                <span className="line"></span>
              </div>
              <div className="bottom">
                <span className="icon">
                  <i className="fa-solid fa-hourglass-half"></i>
                </span>
                <p>
                  Elaboro planes personalizados que cumplan con las necesidades
                  y objetivos de mis clientes con el fin de plasmar sus ideas.
                </p>
              </div>
            </div>
            <div className="card">
              <div className="top">
                <span className="number">03</span>
                <span className="title">Desarrollo</span>
                <span className="line"></span>
              </div>
              <div className="bottom">
                <span className="icon">
                  <i className="fa-solid fa-laptop"></i>
                </span>
                <p>
                  Desarrollo e integro soluciones innovadoras con tecnologías
                  que soporten y transformen los procesos del negocio.
                </p>
              </div>
            </div>
            <div className="card">
              <div className="top">
                <span className="number">04</span>
                <span className="title">Lanzamiento</span>
                <span className="line"></span>
              </div>
              <div className="bottom">
                <span className="icon">
                  <i className="fa-solid fa-cloud"></i>
                </span>
                <p>
                  Implemento los proyectos personalizados para empresas y/o
                  emprendedores que quieren potenciar su negocio con soluciones
                  que se ajusten con sus necesidades.
                </p>
              </div>
            </div>
          </div>
          <div className="button">
            <Link to="/portafolio">Ver Portafolio <i class="fa-solid fa-arrow-right"></i></Link>
          </div>
        </div>
      </section>
      <section className="inicio-canales">
        <div className="container">
          <img
            src={meImages("./foto-perfil.png")}
            alt="Cristian Camilo Alzate Ramirez | Programación y desarrollo web"
          ></img>
          <h3>mis canales de youtube y spotify.</h3>
          <p>
            Comparto contenido en forma de videos y podcasts sobre programación,
            desarrollo web, experiencias y mucho más.
          </p>
          <div className="list-multimedia">
            <a href="https://www.youtube.com/@cristiancamiloalzateramirezweb/">
              <span className="icon">
                <i className="fa-brands fa-youtube"></i>
              </span>
              <span className="text">Youtube</span>
            </a>
            <a href="https://open.spotify.com/show/3xHFVNTx4ihEvGvqerclhM">
              <span className="icon">
                <i className="fa-brands fa-spotify"></i>
              </span>
              <span className="text">Spotify</span>
            </a>
          </div>
        </div>
      </section>
      <section className="inicio-miblog">
        <div className="container">
          <h3>Últimos Articulos</h3>
          <p>
            Aquí tienes una selección de algunos artículos que he escrito
            recientemente.
          </p>
          <div className="list-posts">
            {escritos.slice(0, 6).map((escrito) => {
              return (
                <div className="post">
                  <div className="cover">
                    <Link to={"/articulo/" + escrito.id}>
                      <img
                        src={articleImages(`./${escrito.id}.jpg`)}
                        alt={escrito.title}
                      ></img>
                    </Link>
                  </div>
                  <div className="info">
                    <span className="date">
                      Publicado por: {escrito.user} | {escrito.date}
                    </span>
                    <Link to={"/articulo/" + escrito.id}>{escrito.title}</Link>
                    <p>{escrito.introduction}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="button">
            <Link to="/blog">Ver Blog <i class="fa-solid fa-arrow-right"></i></Link>
          </div>
        </div>
      </section>
      <section className="inicio-empresas">
        <div className="container">
          <h3>Han confiado en mí</h3>
          <p>
            Estas son algunas de las compañías con las que he tenido el
            privilegio de colaborar a lo largo de mi carrera.
          </p>
          <div className="cards">
            <div className="card">
              <a href="https://visionconsultora.co">
                <img
                  src={companyImages(`./vision-consultora.png`)}
                  alt=""
                ></img>
              </a>
            </div>
            <div className="card">
              <a href="https://selectacutflowers.com">
                <img src={companyImages(`./selecta.png`)} alt=""></img>
              </a>
            </div>
            <div className="card">
              <a href="https://ikonico.co">
                <img src={companyImages(`./ikonico.png`)} alt=""></img>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
