import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../articulo/Articulo.css";
import { escritos } from "../../../data/escritos";

export const Articulo = () => {
  const [articulo, setArticulo] = useState({});
  const params = useParams();

  useEffect(() => {
    let articulo = escritos.filter((escrito) => escrito.id === params.id);
    setArticulo(articulo[0]);
  }, [params.id]);

  const articleImages = require.context("../../../assets/images/blog/", true);

  return (
    <>
      <section className="inicio-articulo">
        <div className="imagen-background">
          <div className="slider-typography">
            <div className="slider-main">
              <span className="line"></span>
              <h1>{articulo.title}</h1>
              <p>{articulo.introduction}</p>
            </div>
          </div>
        </div>
      </section>
      <section className="articulo">
        <div className="container">
          <p>
            <img
              src={articleImages(`./${params.id}.jpg`)}
              alt={articulo.title}
            ></img>
          </p>
          <h2>{articulo.title}</h2>
          <p className="firm">
            Publicado por: {articulo.user} | {articulo.date} |{" "}
            {articulo.categories}.
          </p>
          <div dangerouslySetInnerHTML={{ __html: articulo.text }}></div>
        </div>
      </section>
    </>
  );
};
