import React, { useState } from "react";
import { db } from "../../firebase";
import "../contactame/Contactame.css";
import resumen from "../../assets/documents/hoja-vida.pdf";

export const Contactame = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [matter, setMatter] = useState("");
  const [message, setMessage] = useState("");

  const [loader, setLoader] = useState(false);
  const [alert, setAlert] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      name.length !== 0 &&
      email.length !== 0 &&
      phone.length !== 0 &&
      matter.length !== 0 &&
      message.length !== 0
    ) {
      setLoader(true);
      db.collection("usuarios")
        .add({
          nombre: name,
          correo: email,
          telefono: phone,
          asunto: matter,
          mensaje: message,
          fecha: "",
        })
        .then(() => {
          setLoader(false);
          setAlert(
            "<p>¡Gracias por contactarme! Estoy emocionado de recibir tu mensaje y te responderemos lo antes posible. Tu opinión es importante para mí y hare todo lo posible por ayudarte. ¡Ten un maravilloso día!</p>"
          );
        })
        .catch((error) => {
          setAlert(error.message);
          setLoader(false);
        });
      setName("");
      setEmail("");
      setPhone("");
      setMatter("");
      setMessage("");
    } else {
      setAlert(
        "<p>Para poder ayudarte, necesito que ingrese todos los datos.</p>"
      );
    }
  };

  return (
    <>
      <section className="inicio-contactame">
        <div className="imagen-background">
          <div className="slider-typography">
            <div className="slider-main">
              <span className="line"></span>
              <h1>Contactame</h1>
              <p>
                Como freelance, mi lugar de trabajo habitual es en mi casa en
                Rionegro (Antioquia, Colombia). Sin embargo, tengo
                disponibilidad para desplazarme a cualquier lugar donde me
                necesiten. ¿Hablamos? ¡Estoy listo!
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="contactame">
        <div className="container">
          <div className="container-left">
            <h2>Hola, ¿En que te puedo ayudar?</h2>
            <p>
              ¡Me encantaría conocer más sobre ti! Estoy totalmente dispuesto a
              aclarar tus dudas y ayudarte a llevar a cabo tus proyectos e
              ideas. Para contactarme, puedes utilizar el siguiente formulario o
              enviarme un correo electrónico a{" "}
              <a href="mailto:cristiancamiloalzateramirezweb@gmail.com">
                cristiancamiloalzateramirezweb@gmail.com
              </a>{" "}
              y te responderé lo antes posible.
            </p>
            <p>
              Además, estoy presente en las redes sociales:&nbsp;
              <a href="https://www.facebook.com/cristiancamiloalzateramirezweb/">
                Facebook
              </a>
              ,&nbsp;
              <a href="https://www.instagram.com/cristiancamiloalzateramirezweb/">
                Instagram
              </a>
              ,&nbsp;
              <a href="https://twitter.com/ccarweb/">Twitter</a>
              ,&nbsp;
              <a href="https://www.youtube.com/@cristiancamiloalzateramirezweb/">
                YouTube
              </a>
              ,&nbsp;
              <a href="https://www.tiktok.com/@ccarweb/">TikTok</a>
              ,&nbsp;
              <a href="https://github.com/cristiancamiloalzateramirezweb/">
                GitHub
              </a>
              &nbsp;o&nbsp;
              <a href="https://www.linkedin.com/in/cristiancamiloalzateramirezweb/">
                Linkedin.
              </a>
            </p>
            <p>
              También puedes escribirme en&nbsp;
              <a href="https://wa.me/573136048448">Whatsapp</a>
              ,&nbsp;
              <a href="https://t.me/ccarweb/">Telegram</a>
              &nbsp;o&nbsp;
              <a href="https://m.me/cristiancamiloalzateramirezweb/">
                Messenger.
              </a>&nbsp;
              ¡Espero saber pronto de ti y trabajar juntos!
            </p>
            <a class="button" href={resumen} download="HV_CRISTIANCAMILOALZATERAMIREZWEB.pdf">
              Descarga mi hoja de vida <i class="fa-solid fa-arrow-right"></i>
            </a>
          </div>
          <div className="container-right">
            <div className="formulario-container">
              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  placeholder="Nombre Completo"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <input
                  type="email"
                  placeholder="Correo Electrónico"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  type="number"
                  placeholder="Teléfono"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Asunto"
                  value={matter}
                  onChange={(e) => setMatter(e.target.value)}
                />
                <textarea
                  placeholder="Mensaje"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
                <span dangerouslySetInnerHTML={{ __html: alert }}></span>
                <button type="submit" className={`${loader ? "active" : ""}`}>
                  Enviar Mensaje <i class="fa-solid fa-arrow-right"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section className="google-maps">
        <div className="container">
          <iframe 
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d833.9173612911936!2d-75.37390617236126!3d6.157289185068797!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6dfa4cb2a044653b%3A0xe539d1518d00f271!2sCristian%20Camilo%20Alzate%20Ramirez%20Web!5e0!3m2!1ses!2sco!4v1704750610045!5m2!1ses!2sco"
            title="Cristian Camilo ALzate Ramirez Web"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section>
    </>
  );
};
