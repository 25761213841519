import React from "react";
import { NavLink } from "react-router-dom";
import "../footer/Footer.css";
import { Botones } from "../botones/Botones";

export const Footer = () => {
  const yearActual = new Date().getFullYear();
  return (
    <footer>
      <div className="footer-main">
        <div className="marca-container">
          <h5>Sobre Mí</h5>
          <p>
            Mi nombre es Cristian Camilo Alzate Ramirez, desarrollador de
            software emprendedor.
          </p>
          <p>
            <strong>Cristian Camilo Alzate Ramirez Web</strong>, es un reflejo de mi
            sueño de crecer como profesional en el mundo del desarrollo de
            software. Desde entonces, me he dedicado a especializarme en el
            desarrollo de páginas y aplicaciones web, colaborando con dos
            empresas y fundando mi propio emprendimiento. La programación, la
            tecnología, la informática y el mundo de Internet me apasionan
            profundamente. Disfruto creando cosas nuevas, aprendiendo y
            compartiendo conocimientos con los demás. Cada día, dedicarme a
            estas actividades me brinda una gran felicidad y satisfacción en mi
            vida.
          </p>
        </div>
        <div className="menu-container">
          <h5>Mi Menú</h5>
          <ul>
            <li>
              <NavLink to="/inicio">Inicio</NavLink>
            </li>
            <li>
              <NavLink to="/sobre-mi">Sobre Mí</NavLink>
            </li>
            <li>
              <NavLink to="/servicios">Servicios</NavLink>
            </li>
            <li>
              <NavLink to="/portafolio">Portafolio</NavLink>
            </li>
            <li>
              <NavLink to="/blog">Blog</NavLink>
            </li>
            <li>
              <NavLink to="/contactame">Contactame</NavLink>
            </li>
          </ul>
        </div>
        <div className="contacto-container">
          <div className="contacto-basico">
            <h5>Contacto</h5>
            <ul>
              <li>
                <a href="https://maps.app.goo.gl/kSJetXKYkKi1ToQa8" target="blank">
                  <span className="icon">
                    <i className="fa-solid fa-location-dot"></i>
                  </span>
                  <span className="text">
                    Rionegro, Antioquia, Colombia
                  </span>
                </a>
              </li>
              <li>
                <a href="mailto:cristiancamiloalzateramirezweb@gmail.com">
                  <span className="icon">
                    <i className="fa-solid fa-envelope"></i>
                  </span>
                  <span className="text">
                    cristiancamiloalzateramirezweb@gmail.com
                  </span>
                </a>
              </li>
              <li>
                <a href="tel:573136048448">
                  <span className="icon">
                    <i class="fa-solid fa-phone"></i>
                  </span>
                  <span className="text">+57 3136048448</span>
                </a>
              </li>
              <li>
                <p>
                  <span className="icon">
                    <i class="fa-solid fa-clock"></i>
                  </span>
                  <span className="text">
                    <strong>Lunes - Viernes</strong> 8:00 a.m/6:00 p.m
                  </span>
                </p>
              </li>
            </ul>
          </div>
          <div className="contacto-social">
            <ul>
              <li>
                <a href="https://www.facebook.com/cristiancamiloalzateramirezweb/">
                  <i className="fa-brands fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/cristiancamiloalzateramirezweb/">
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/ccarweb/">
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/@cristiancamiloalzateramirezweb/">
                  <i className="fab fa-youtube"></i>
                </a>
              </li>
              <li>
                <a href="https://www.tiktok.com/@ccarweb/">
                  <i className="fa-brands fa-tiktok"></i>
                </a>
              </li>
              <li>
                <a href="https://github.com/cristiancamiloalzateramirezweb/">
                  <i className="fa-brands fa-github"></i>
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/in/cristiancamiloalzateramirezweb/">
                  <i className="fa-brands fa-linkedin-in"></i>
                </a>
              </li>
              <li>
                <a href="https://discord.gg/NC7VCWJU">
                  <i className="fa-brands fa-discord"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="firma">
        <p>
          &copy; 2020-{yearActual} cristian camilo alzate ramirez web por
          Cristian Camilo Alzate Ramirez. Desarrollando Software con
          <span className="icon">
            <i className="fa-solid fa-heart"></i>
          </span>
          en colombia para el mundo.
        </p>
        <a href="/" className="logo">
          cristiancamiloalzateramirezweb
        </a>
      </div>
      <Botones></Botones>
    </footer>
  );
};
