import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "../navegacion/Navegacion.css";

export const Navegacion = () => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <header>
        <div className="header-main">
          <div className="nav-main">
            <div className="logo-container">
              <a href="/" className="logo">
                cristiancamiloalzateramirezweb
              </a>
            </div>
            <nav className="nav-container">
              <ul>
                <li>
                  <NavLink to="/inicio">Inicio</NavLink>
                </li>
                <li>
                  <NavLink to="/sobre-mi">Sobre Mí</NavLink>
                </li>
                <li>
                  <NavLink to="/servicios">Servicios</NavLink>
                </li>
                <li>
                  <NavLink to="/portafolio">Portafolio</NavLink>
                </li>
                <li>
                  <NavLink to="/blog">Blog</NavLink>
                </li>
                <li>
                  <NavLink to="/contactame">Contactame</NavLink>
                </li>
              </ul>
            </nav>
            <button className="btn-menu" onClick={handleClick}>
              <i className="fa-solid fa-bars-staggered"></i>
            </button>
          </div>
        </div>
      </header>
      <section className={`header-movil ${open ? "active-header-movil" : ""}`}>
        <div className="container" onClick={handleClick}>
          <button className="btn-menu" onClick={handleClick}>
            <i className="fa-solid fa-xmark"></i>
          </button>
          <nav className="nav-main">
            <div className="nav-container">
              <ul>
                <li>
                  <NavLink to="/inicio">Inicio</NavLink>
                </li>
                <li>
                  <NavLink to="/sobre-mi">Sobre Mí</NavLink>
                </li>
                <li>
                  <NavLink to="/servicios">Servicios</NavLink>
                </li>
                <li>
                  <NavLink to="/portafolio">Portafolio</NavLink>
                </li>
                <li>
                  <NavLink to="/blog">Blog</NavLink>
                </li>
                <li>
                  <NavLink to="/contactame">Contactame</NavLink>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </section>
    </>
  );
};
