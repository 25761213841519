export const servicios = [
  {
    id: "desarrollo-web",
    icon: "fa-solid fa-desktop",
    name: "Desarrollo Web",
    title:
      "Impulsa tu presencia en línea con mi servicio de desarrollo web de alta calidad, destacando en el mundo digital.",
    services: [
      {
        code: "1",
        icon: "fa-solid fa-laptop",
        text: "Páginas de Destino",
      },
      {
        code: "2",
        icon: "fa-brands fa-wordpress-simple",
        text: "Sitios de WordPress",
      },
      {
        code: "3",
        icon: "fa-regular fa-building",
        text: "Sitios Corporativos",
      },
      {
        code: "4",
        icon: "fa-solid fa-laptop-code",
        text: "Aplicaciones Web",
      },
      {
        code: "5",
        icon: "fa-solid fa-shop",
        text: "Tiendas Virtuales",
      },
    ],
    intro:
      "Soy un experto en desarrollo web, ofreciendo diseño de páginas de destino, sitios de WordPress, sitios corporativos, aplicaciones web y tiendas virtuales. Con enfoque centrado en el usuario, transformamos tus ideas en experiencias digitales sorprendentes. ¡Haz despegar tu presencia en línea conmigo!",
    text: "Brindo servicios personalizado de diseño y programación de sitios web, desde páginas de destino hasta sitios de WordPress, sitios corporativos, aplicaciones web y tiendas virtuales. Con conocimientos y experiencia en diversas tecnologías de desarrollo web, estoy listo para ayudarte a crear un sitio web rápido, seguro y fácil de usar que se adapte a tus necesidades.",
    bigText:
      "<p>Como desarrollador web capacitado, me especializo en la creación de sitios modernos, funcionales y atractivos. Colaboro estrechamente contigo para comprender tus objetivos, identificar necesidades específicas y diseñar una solución web personalizada que refleje la esencia de tu marca.</p><p>Desde el análisis, planificación, implementación y optimización, abarco todos los aspectos del desarrollo web. Utilizo tecnologías de vanguardia y las mejores prácticas de la industria para garantizar que tu sitio esté optimizado en velocidad, accesibilidad y experiencia del usuario.</p><p>Mi compromiso va más allá del desarrollo inicial también ofrezco mantenimiento y actualizaciones del sitio web. Ya sea que necesites una página de destino, sitios en WordPress, sitio personal, sitio corporativo, aplicaciones web personalizadas o tiendas virtuales, estoy preparado para asumir desafíos. Mi dedicación a la excelencia y la atención me distingue como un proveedor de servicios en programación y desarrollo web de confianza. No importa el tamaño de tu negocio ni tu industria, estoy comprometido a ayudarte a alcanzar tus metas en línea.</p>",
  },
  {
    id: "diseno-grafico",
    icon: "fa-solid fa-palette",
    name: "Diseño Gráfico",
    title:
      "Destaque su marca con diseños gráficos únicos y profesionales que capturan su esencia.",
    services: [
      {
        code: "1",
        icon: "fa-solid fa-image",
        text: "Diseño UX/UI",
      },
      {
        code: "2",
        icon: "fa-solid fa-pen-ruler",
        text: "Diseño de Logotipos",
      },
      {
        code: "3",
        icon: "fa-solid fa-address-card",
        text: "Imagen Corporativa",
      },
      {
        code: "4",
        icon: "fa-solid fa-photo-film",
        text: "Elementos Gráficos",
      },
    ],
    intro:
      "Tu aliado en diseño gráfico. Desde diseño UX/UI hasta logos, imagen corporativa y elementos gráficos, creo experiencias visuales únicas para potenciar tu marca. Transforma tu identidad con mi toque creativo.",
    text: "Ofrezco servicios de diseño gráfico personalizado para llevar tu marca al siguiente nivel. Desde el diseño UX/UI hasta logos, imagen corporativa y elementos gráficos, puedo ayudarte a crear una imagen profesional que haga destacar tu negocio.",
    bigText:
      "<p>Como especialista en diseño gráfico, me dedico a crear diseños UX/UI, logos, imagen corporativa y elementos gráficos de alta calidad. Mi enfoque se centra en soluciones personalizadas adaptadas a las necesidades de cada cliente,asegurando que los diseños cumplan con requisitos mínimos y satisfagan de manera efectiva las necesidades del proyecto.</p><p>En la creación de logotipos, mi enfoque se basa en comprender la visión y valores del cliente para desarrollar diseños únicos y memorables que reflejen su identidad de manera impactante. En el diseño de elementos gráficos, busco crear piezas visuales atractivas y efectivas que capten la atención del público y transmitan el mensaje de manera clara y persuasiva. En cuanto al diseño UX/UI, me concentro en crear elementos visuales atractivos y funcionales que mejoren la experiencia del usuario y transmitan el mensaje de manera clara y efectiva.</p><p>Utilizo técnicas de composición, selección de colores, tipografía y elementos visuales cuidadosamente seleccionados para destacar los beneficios de los productos o servicios de mis clientes y generar un impacto visual positivo. Además, me comprometo a trabajar estrechamente con mis clientes, comprendiendo sus necesidades, visiones y metas, y estoy siempre dispuesto a colaborar y adaptar mis diseños según sus preferencias y requerimientos específicos.</p>",
  },
  {
    id: "consultoria-ti",
    icon: "fa-solid fa-gear",
    name: "Consultoría TI",
    title:
      "Potencie y resalte su marca mediante servicios profesionales de mantenimiento, posicionamiento y asesoría web que reflejen su esencia",
    services: [
      {
        code: "1",
        icon: "fa-solid fa-screwdriver-wrench",
        text: "Mantenimiento Web",
      },
      {
        code: "2",
        icon: "fa-solid fa-database",
        text: "Gestión de Base de Datos",
      },
      {
        code: "3",
        icon: "fa-solid fa-magnifying-glass",
        text: "Posicionamiento Web",
      },
      {
        code: "4",
        icon: "fa-solid fa-comments",
        text: "Asesorías TI",
      },
    ],
    intro:
      "Experto en consultoría TI. Ofrecezco mantenimiento web, gestión de bases de datos, posicionamiento web y asesoría especializada para impulsar tu éxito digital. Confía en mi para optimizar tu presencia en línea.",
    text: "Ofrezco servicios de consultoría IT personalizada para potenciar tus proyectos y llevar tu marca al siguiente nivel. Desde el mantenimiento web hasta la gestión de bases de datos, posicionamiento web y asesorías en tecnologías de la información, puedo ayudarte a optimizar tu marca y destacar tu negocio.",
    bigText:
      "<p>Destaque su presencia en línea con mis servicios especializados en mantenimiento y actualización web. Me comprometo a garantizar que su sitio web esté siempre en su mejor forma, realizando actualizaciones regulares para mantener la funcionalidad, seguridad y eficiencia. Con un enfoque proactivo, abordamos cualquier problema potencial antes de que afecte su rendimiento, brindándole la tranquilidad de contar con una presencia digital siempre actualizada y óptima.</p><p>La gestión efectiva de bases de datos es esencial para el funcionamiento eficiente de cualquier sitio web. Me especializo en optimizar y administrar bases de datos para garantizar un rendimiento óptimo, rápida accesibilidad y seguridad. Desde el modelado de esquemas, hasta la optimización continua, me encargo de todos los aspectos para que pueda centrarse en lo que hace mejor crecer su negocio.</p><p>Potencie su visibilidad en línea con nuestras estrategias de posicionamiento web. Me enfoque va más allá de la creación de un sitio atractivo trabajo para que su negocio destaque en los motores de búsqueda y alcance a su audiencia objetivo. Además, ofrezco asesoría en tecnología de la información para guiarlo en la toma de decisiones informadas, asegurándonos de que su infraestructura tecnológica respalde de manera eficaz sus objetivos. Confíe en mi para elevar su presencia en línea.</p>",
  }
];
