import React from "react";
import "../sobre-mi/SobreMi.css";

export const SobreMi = () => {
  const skillImages = require.context("../../assets/images/habilidades", true);
  const meImages = require.context("../../assets/images/marca/", true);
  const companyImages = require.context("../../assets/images/empresas/", true);

  return (
    <>
      <section className="sobremi-inicio">
        <div className="imagen-background">
          <div className="slider-typography">
            <div className="slider-main">
              <span className="second-title">hola, mi nombre es</span>
              <span className="title">cristian camilo alzate ramirez</span>
              <span className="pro">Programador y Desarrollador Web</span>
            </div>
            <div className="footer-banner">
              desarrollando software con
              <span className="icon">
                <i className="fa-solid fa-heart"></i>
              </span>
              en colombia para el mundo.
            </div>
          </div>
        </div>
      </section>
      <section className="sobre-mi">
        <div className="container">
          <h1>Mi historia</h1>
          <p>
            ¡Hola! Mi nombre es <strong>Cristian Camilo Alzate Ramírez</strong>,
            un apasionado informático emprendedor de El Carmen de Viboral,
            Antioquia, Colombia. Desde muy joven, mi fascinación por la
            programación y la tecnología ha sido innegable. En el año 2020, tomé
            la decisión de seguir mi corazón y me inscribí en el programa de
            Tecnología en Análisis y Desarrollo de Software en el Servicio
            Nacional de Aprendizaje SENA, donde mi conocimiento y habilidades
            florecieron como nunca antes.
          </p>
          <p>
            A lo largo de mi carrera, tuve la oportunidad de trabajar en
            compañías como Selecta Colombia e Ikonico 360, lo que me permitió
            adquirir experiencia valiosa en el mundo laboral. Sin embargo, mi
            espíritu emprendedor siempre anhelaba más libertad y desafíos. Es
            por eso que decidí dar un paso audaz y convertirme en freelancer, lo
            que me ha permitido participar en diversos y emocionantes proyectos.
          </p>
          <p>
            Además de mi amor por la programación, hay otros aspectos que me
            definen como persona. Me encanta la adrenalina de las aventuras en
            motos, bicicletas y caminatas, que me conectan con la naturaleza y
            me ayudan a liberar tensiones. El fútbol, la música, la fotografía y
            el cine también son pasiones que me inspiran y enriquecen mi vida
            diaria. Cada día es una oportunidad para aprender, crecer y escribir
            mi propia historia como un apasionado informático emprendedor, y
            estoy emocionado por lo que el futuro tiene reservado para mí.
          </p>
          <ul>
            <li>
              <i className="fa-solid fa-laptop-code"></i>
              <p>Programar</p>
            </li>
            <li>
              <i className="fa-solid fa-laptop"></i>
              <p>Desarrollar</p>
            </li>
            <li>
              <i className="fa-sharp fa-solid fa-bicycle"></i>
              <p>Bicirutar</p>
            </li>
            <li>
              <i className="fa-solid fa-motorcycle"></i>
              <p>Motorutar</p>
            </li>
            <li>
              <i className="fa-solid fa-mountain-sun"></i>
              <p>Senderar</p>
            </li>
            <li>
              <i className="fa-solid fa-futbol"></i>
              <p>Jugar</p>
            </li>
            <li>
              <i className="fa-solid fa-headphones"></i>
              <p>Escuchar</p>
            </li>
            <li>
              <i className="fa-solid fa-camera"></i>
              <p>Crear</p>
            </li>
          </ul>
        </div>
      </section>
      <section className="inicio-canales">
        <div className="container">
          <img
            src={meImages("./foto-perfil.png")}
            alt="Cristian Camilo Alzate Ramirez | Programación y desarrollo web"
          ></img>
          <h3>mis canales de youtube y spotify.</h3>
          <p>
            Comparto contenido en forma de videos y podcasts sobre programación,
            desarrollo web, experiencias y mucho más.
          </p>
          <div className="list-multimedia">
            <a href="https://www.youtube.com/@cristiancamiloalzateramirezweb/">
              <span className="icon">
                <i className="fa-brands fa-youtube"></i>
              </span>
              <span className="text">Youtube</span>
            </a>
            <a href="https://open.spotify.com/show/3xHFVNTx4ihEvGvqerclhM">
              <span className="icon">
                <i className="fa-brands fa-spotify"></i>
              </span>
              <span className="text">Spotify</span>
            </a>
          </div>
        </div>
      </section>
      <section className="habilidades">
        <div className="container">
          <h2>Mis Habilidades</h2>
          <p>
            Me especializo en el desarrollo de software con enfoque en la creación de páginas y
            aplicaciones web. Mi experiencia abarca tanto el diseño como el desarrollo, tanto
            del lado del cliente como del servidor. Además, aplico mis conocimientos en diseño
            gráfico para crear experiencias visuales atractivas. Como profesional autodidacta,
            me esfuerzo constantemente por ampliar mis habilidades, ya que entiendo que esta es
            una profesión en la que nunca se deja de aprender.
          </p>
          <div className="skills">
            <div className="skill">
              <div className="skill-top">
                <div className="circle-img">
                  <img src={skillImages(`./html.svg`)} alt="HTML"></img>
                </div>
              </div>
              <p>HTML</p>
            </div>
            <div className="skill">
              <div className="skill-top">
                <div className="circle-img">
                  <img src={skillImages(`./css.svg`)} alt="CSS"></img>
                </div>
              </div>
              <p>CSS</p>
            </div>
            <div className="skill">
              <div className="skill-top">
                <div className="circle-img">
                  <img src={skillImages(`./sass.svg`)} alt="Sass"></img>
                </div>
              </div>
              <p>Sass</p>
            </div>
            <div className="skill">
              <div className="skill-top">
                <div className="circle-img">
                  <img src={skillImages(`./bootstrap.svg`)} alt="Bootstrap"></img>
                </div>
              </div>
              <p>Bootstrap</p>
            </div>
            <div className="skill">
              <div className="skill-top">
                <div className="circle-img">
                  <img
                    src={skillImages(`./javascript.svg`)}
                    alt="JavaScript"
                  ></img>
                </div>
              </div>
              <p>JavaScript</p>
            </div>
            <div className="skill">
              <div className="skill-top">
                <div className="circle-img">
                  <img
                    src={skillImages(`./typescript.svg`)}
                    alt="TypeScript"
                  ></img>
                </div>
              </div>
              <p>TypeScript</p>
            </div>
            <div className="skill">
              <div className="skill-top">
                <div className="circle-img">
                  <img src={skillImages(`./jquery.svg`)} alt="JQuery"></img>
                </div>
              </div>
              <p>JQuery</p>
            </div>
            <div className="skill">
              <div className="skill-top">
                <div className="circle-img">
                  <img src={skillImages(`./react.svg`)} alt="React"></img>
                </div>
              </div>
              <p>React</p>
            </div>
            <div className="skill">
              <div className="skill-top">
                <div className="circle-img">
                  <img src={skillImages(`./angular.svg`)} alt="Angular"></img>
                </div>
              </div>
              <p>Angular</p>
            </div>
            <div className="skill">
              <div className="skill-top">
                <div className="circle-img">
                  <img src={skillImages(`./figma.svg`)} alt="Figma"></img>
                </div>
              </div>
              <p>Figma</p>
            </div>
            <div className="skill">
              <div className="skill-top">
                <div className="circle-img">
                  <img src={skillImages(`./java.svg`)} alt="Java"></img>
                </div>
              </div>
              <p>Java</p>
            </div>
            <div className="skill">
              <div className="skill-top">
                <div className="circle-img">
                  <img src={skillImages(`./php.svg`)} alt="PHP"></img>
                </div>
              </div>
              <p>PHP</p>
            </div>
            <div className="skill">
              <div className="skill-top">
                <div className="circle-img">
                  <img src={skillImages(`./mysql.svg`)} alt="MySQL"></img>
                </div>
              </div>
              <p>MySQL</p>
            </div>
            <div className="skill">
              <div className="skill-top">
                <div className="circle-img">
                  <img src={skillImages(`./firebase.svg`)} alt="Firebase"></img>
                </div>
              </div>
              <p>Firebase</p>
            </div>
            <div className="skill">
              <div className="skill-top">
                <div className="circle-img">
                  <img src={skillImages(`./git.svg`)} alt="Git"></img>
                </div>
              </div>
              <p>Git</p>
            </div>
            <div className="skill">
              <div className="skill-top">
                <div className="circle-img">
                  <img src={skillImages(`./github.svg`)} alt="GitHub"></img>
                </div>
              </div>
              <p>GitHub</p>
            </div>
            <div className="skill">
              <div className="skill-top">
                <div className="circle-img">
                  <img
                    src={skillImages(`./wordpress.svg`)}
                    alt="WordPress"
                  ></img>
                </div>
              </div>
              <p>WordPress</p>
            </div>
            <div className="skill">
              <div className="skill-top">
                <div className="circle-img">
                  <img
                    src={skillImages(`./aws.svg`)}
                    alt="AWS"
                  ></img>
                </div>
              </div>
              <p>AWS</p>
            </div>
            <div className="skill">
              <div className="skill-top">
                <div className="circle-img">
                  <img
                    src={skillImages(`./vscode.svg`)}
                    alt="Visual Studio Code"
                  ></img>
                </div>
              </div>
              <p>Visual Studio Code</p>
            </div>
            <div className="skill">
              <div className="skill-top">
                <div className="circle-img">
                  <img
                    src={skillImages(`./netbeans.svg`)}
                    alt="NetBeans"
                  ></img>
                </div>
              </div>
              <p>NetBeans</p>
            </div>
          </div>
        </div>
      </section>
      <section className="inicio-empresas">
        <div className="container">
          <h3>Han confiado en mí</h3>
          <p>
            Estas son algunas de las compañías con las que he tenido el
            privilegio de colaborar a lo largo de mi carrera.
          </p>
          <div className="cards">
            <div className="card">
              <a href="https://visionconsultora.co">
                <img
                  src={companyImages(`./vision-consultora.png`)}
                  alt=""
                ></img>
              </a>
            </div>
            <div className="card">
              <a href="https://selectacutflowers.com">
                <img src={companyImages(`./selecta.png`)} alt=""></img>
              </a>
            </div>
            <div className="card">
              <a href="https://ikonico.co">
                <img src={companyImages(`./ikonico.png`)} alt=""></img>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

//me gusta, me encanta, me importa, me divierte, me asombra, me entristece, me enoja
