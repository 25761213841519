import React from "react";
import { Link } from "react-router-dom";
import "../blog/Blog.css";
import { escritos } from "../../../data/escritos";

export const Blog = () => {
  const articleImages = require.context("../../../assets/images/blog/", true);

  return (
    <>
      <section className="inicio-blog">
        <div className="imagen-background">
          <div className="slider-typography">
            <div className="slider-main">
              <span className="line"></span>
              <h1>Blog</h1>
              <p>
                Aquí compartiré mi experiencia y todo lo relacionado con mi
                trabajo y profesión, incluyendo las herramientas y recursos que
                utilizo diariamente. Espero que encuentren esta información útil
                y les agradezco sus comentarios y sugerencias.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="blog">
        <div className="container">
          <div className="list-posts">
            {escritos.map((escrito) => {
              return (
                <div className="post">
                  <div className="cover">
                    <Link to={"/articulo/" + escrito.id}>
                      <img
                        src={articleImages(`./${escrito.id}.jpg`)}
                        alt={escrito.title}
                      ></img>
                    </Link>
                  </div>
                  <div className="info">
                    <span className="date">
                      Publicado por: {escrito.user} | {escrito.date}
                    </span>
                    <Link to={"/articulo/" + escrito.id}>{escrito.title}</Link>
                    <p>{escrito.introduction}</p>
                    <Link className="button" to={"/articulo/" + escrito.id}>
                      Continuar leyendo <i class="fa-solid fa-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};
