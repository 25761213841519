export const trabajos = [
  {
    id: "tienda-agricola",
    name: "Tienda agricola",
    creator: "Cristian Camilo Alzate Ramirez",
    client: "Yo",
    date: "02 Noviembre, 2022",
    introduction: "Tienda agrícola en línea que permite a los usuarios buscar, comprar y vender productos en línea del agro colombiano. La tienda agrícola cuenta con una amplia variedad de categorías de los productos como lo son: ganadería, mascotas, alimentos e insumos.",
    description:
      "<p>Tienda agrícola en línea que permite a los usuarios buscar, comprar y vender productos en línea del agro colombiano. La tienda agrícola cuenta con una amplia variedad de categorías de los productos como lo son: ganadería, mascotas, alimentos e insumos. Cada categoria esta divida con múltiples secciones correspondiente a la categoria seleccionada. El objetivo del proyecto es crear una plataforma en línea fácil de usar y segura para los usuarios, donde el propósito es minimizar intermediarios en la venta y compra de productos del campo y transformar la vida de los campesinos colombianos.</p><h3>Arquitectura del proyecto</h3><p>La tienda agrícola en línea está construida en una arquitectura cliente servidor. Los usuarios interactúan con la interfaz y realizan peticiones al servidor mediante Ajax con JQuery. El servidor está construido en Java y utiliza una base de datos MySQL para almacenar la información de productos y usuarios. La interfaz está construida en HTML, CSS, JavaScript para gestionar la respuesta del servidor.</p><h3>Proceso de desarrollo</h3><p>El proceso de desarrollo utilizado fue la metodología ágil. Desde la etapa de análisis, planeación, desarrollo, pruebas y el mantenimiento.</p><h3>Detalles</h3>",
    website: "https://github.com/cristiancamiloalzateramirezweb/tierragricola",
    type: "Web",
    sector: "Agricultura",
    tecnologies: "HTML, CSS, JavaScript, JQuery, Java y MySQL."
  },
  {
    id: "administrador-tareas",
    name: "Administrador de Tareas",
    creator: "Cristian Camilo Alzate Ramirez",
    client: "Yo",
    date: "14 Octubre, 2022",
    introduction: "Administrador de Tareas en línea capacita a los usuarios para crear y gestionar tareas desde un centro único y conveniente. Es crucial para organizar tareas personales, académicas y laborales, garantizando un control eficiente y completo.",
    description:
      "<p>Administrador de Tareas herramienta en línea de gran potencia que brinda a los usuarios la capacidad de crear y administrar sus tareas pendientes desde un centro de control único y conveniente. Esta herramienta se transforma en un aliado esencial para la organización de tareas personales, académicas, laborales y profesionales, ofreciendo un método altamente eficiente para mantener todo perfectamente bajo control.</p><h3>Detalles</h3>",
    website: "https://cristiancamiloalzateramirezweb.github.io/lista-de-tareas",
    type: "Web",
    sector: "Personal, Estudiantil y Laboral",
    tecnologies: "HTML, CSS, JavaScript y Localstorage."
  }
  ,
  {
    id: "ikonico",
    name: "Ikonico",
    creator: "Cristian Camilo Alzate Ramirez",
    client: "Ikonico Latam SAS",
    date: "15 Marzo, 2023",
    introduction: "Pagina web de destino de ikonico 360, para atraer clientes potenciales...",
    description:
      "<p>Pagina web de destino de ikonico 360, para atraer clientes potenciales. Entre sus caracteristicas más destacables es que contiene los planes y servicios prestados por la compañia, blog y formulario de contacto.<p><h3>Detalles</h3>",
    website: "https://ikonico.co",
    type: "Web",
    sector: "Marketing Digital",
    tecnologies: "HTML, CSS, Sass, Angular y Firebase."
  }
]
