import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

var firebaseConfig = {
  apiKey: "AIzaSyCNLUVVt_zoaq_QYGePkWjFDhl5yPkCqwU",
  authDomain: "cristiancamiloalzaterami-61d75.firebaseapp.com",
  projectId: "cristiancamiloalzaterami-61d75",
  storageBucket: "cristiancamiloalzaterami-61d75.appspot.com",
  messagingSenderId: "343716124782",
  appId: "1:343716124782:web:2b6a34e82deb9dfd72ee7f",
  measurementId: "G-1KM8LWZE0M",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
export { db };
