import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../proyecto/Proyecto.css";
import { trabajos } from "../../../data/trabajos";

export const Proyecto = () => {
  const [proyecto, setProyecto] = useState({});
  const params = useParams();

  const proyectImages = require.context(
    "../../../assets/images/portafolio/",
    true
  );

  useEffect(() => {
    let proyecto = trabajos.filter((trabajo) => trabajo.id === params.id);
    setProyecto(proyecto[0]);
  }, [params.id]);

  return (
    <>
      <section className="inicio-proyecto">
        <div className="imagen-background">
          <div className="slider-typography">
            <div className="slider-main">
              <span className="line"></span>
              <h1>{proyecto.name}</h1>
              <p>{proyecto.introduction}</p>
            </div>
          </div>
        </div>
      </section>
      <section className="proyecto">
        <div className="container">
          <p>
            <video 
              autoplay="autoplay" loop="loop" muted="muted"
              src={proyectImages(`./${params.id}.mp4`)}>
            </video>
          </p>
          <h2>{proyecto.name}</h2>
          <p className="firm">Realizado por: {proyecto.creator} | {proyecto.date} | {proyecto.type}</p>
          <div dangerouslySetInnerHTML={{ __html: proyecto.description }}></div>
          <p>Tecnologias: <strong>{proyecto.tecnologies}</strong></p>
          <p>Cliente: <strong>{proyecto.client}</strong></p>
          <p>Sector: <strong>{proyecto.sector}</strong></p>
          <p>Enlace: <strong><a href={proyecto.website}>{proyecto.website}</a></strong></p>
        </div>
      </section>
    </>
  );
};
