window.addEventListener('scroll', function() {
    const nav = document.querySelector('header');
    if (window.scrollY > 0) {
      nav.classList.add('header-mov');
    } else {
      nav.classList.remove('header-mov');
    }
});


  
