import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

import { Inicio } from '../pages/inicio/Inicio'
import { SobreMi } from '../pages/sobre-mi/SobreMi'
import { Servicios } from '../pages/servicios/servicios/Servicios'
import { Servicio } from '../pages/servicios/servicio/Servicio'
import { Portafolio } from '../pages/trabajos/portafolio/Portafolio'
import { Proyecto } from '../pages/trabajos/proyecto/Proyecto'
import { Blog } from '../pages/escritos/blog/Blog'
import { Articulo } from '../pages/escritos/articulo/Articulo'
import { Contactame } from '../pages/contactame/Contactame'
import { Error } from '../pages/error/Error'

import { Navegacion } from '../components/navegacion/Navegacion'
import { Footer } from '../components/footer/Footer'
import ScrollToTop from '../assets/js/ScrollToTop';

export const Router = () => {
  return (
    <BrowserRouter>
        <ScrollToTop></ScrollToTop>
        <Navegacion></Navegacion>
        <Routes>
          <Route path='/' element={<Inicio></Inicio>}></Route>
          <Route path='/inicio' element={<Inicio></Inicio>}></Route>
          <Route path='/sobre-mi' element={<SobreMi></SobreMi>}></Route>
          <Route path='/servicios' element={<Servicios></Servicios>}></Route>
          <Route path='/servicio/:id' element={<Servicio></Servicio>}></Route>
          <Route path='/portafolio' element={<Portafolio></Portafolio>}></Route>
          <Route path='/proyecto/:id' element={<Proyecto></Proyecto>}></Route>
          <Route path='/blog' element={<Blog></Blog>}></Route>
          <Route path='/articulo/:id' element={<Articulo></Articulo>}></Route>
          <Route path='/contactame' element={<Contactame></Contactame>}></Route>
          <Route path='*' element={<Error></Error>}></Route>
        </Routes>
        <Footer></Footer>
    </BrowserRouter>
  )
}
