import React from "react";
import { Link } from "react-router-dom";
import "../servicios/Servicios.css";
import { servicios } from "../../../data/servicios";

export const Servicios = () => {
  return (
    <>
      <section className="inicio-servicios">
        <div className="imagen-background">
          <div className="slider-typography">
            <div className="slider-main">
              <span className="line"></span>
              <h1>Servicios</h1>
              <p>
                Aquí encontrarás una amplia gama de servicios creados para
                adaptarse a tus necesidades, los cuales tengo preparados
                especialmente para ti. Mi objetivo es proporcionar soluciones
                efectivas y eficientes para ayudarte a alcanzar tus propósitos
                de manera satisfactoria.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="services">
        <div className="container">
          <div className="list-services">
            {servicios.map((servicio) => {
              return (
                <div className="item-service" id={servicio.id}>
                  <div className="image">
                    <i className={servicio.icon}></i>
                  </div>
                  <div className="texto">
                    <Link to={"/servicio/" + servicio.id}>{servicio.name}</Link>
                    <p>{servicio.text}</p>
                    <p>
                      <Link class="button" to={"/servicio/" + servicio.id}>Leer Más <i class="fa-solid fa-arrow-right"></i></Link>
                    </p>
                  </div>
                  <div className="lista">
                    <h3>¿Qué servicios ofrezco?</h3>
                    <ul>
                      {servicio.services.map((elemento) => (
                        <li key={elemento.code}>{elemento.text}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};
