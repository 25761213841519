import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../botones/Botones.css";

export const Botones = () => {

  const [open, setActivarHablemos] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const activarHablemos = () => {
    setActivarHablemos(!open);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 200) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const paraArriba = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <>
      <div className={`container-hablemos ${open ? "activar-mensajeria" : ""}`} onClick={activarHablemos}>
        <i className={`fa-solid fa-comment-dots ${open ? "fa-solid fa-xmark" : "fa-solid fa-comment-dots"}`}></i>
      </div>
      <div className={`container-mensajeria ${open ? "activar-mensajeria" : ""}`}>
        <ul>
          <li>
            <Link to="/contactame">
              <span className="icon">
                <i class="fa-solid fa-id-card"></i>
              </span>
              <span className="text">Hablemos</span>
            </Link>
          </li>
          <li>
            <a href="mailto:cristiancamiloalzateramirezweb@gmail.com">
              <span className="icon">
                <i className="fa-solid fa-envelope"></i>
              </span>
              <span className="text">E-mail</span>
            </a>
          </li>
          <li>
            <a href="https://wa.me/573136048448">
              <span className="icon">
                <i className="fab fa-whatsapp"></i>
              </span>
              <span className="text">Whatsapp</span>
            </a>
          </li>
          <li>
            <a href="https://t.me/ccarweb/">
              <span className="icon">
                <i className="fab fa-telegram-plane"></i>
              </span>
              <span className="text">Telegram</span>
            </a>
          </li>
          <li>
            <a href="https://m.me/cristiancamiloalzateramirezweb/">
              <span className="icon">
                <i className="fab fa-facebook-messenger"></i>
              </span>
              <span className="text">Messenger</span>
            </a>
          </li>
        </ul>
      </div>
      <div className={`container-fijo ${showButton ? "mostrar" : ""}`} onClick={paraArriba}>
        <i class="fa-solid fa-chevron-up"></i>
      </div>
    </>
  );
};
