import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import "../servicio/Servicio.css";
import { servicios } from "../../../data/servicios";

export const Servicio = () => {
  const [servicio, setServicio] = useState({});
  const params = useParams();

  useEffect(() => {
    let servicio = servicios.filter((servicio) => servicio.id === params.id);
    setServicio(servicio[0]);
  }, [params.id]);

  return (
    <>
      <section className="inicio-servicio">
        <div className="imagen-background">
          <div className="slider-typography">
            <div className="slider-main">
              <span className="line"></span>
              <h1>{servicio.name}</h1>
              <p>{servicio.title}</p>
              <Link to="/contactame">¡Contactame ahora y descubre cómo! <i class="fa-solid fa-arrow-right"></i></Link>
            </div>
          </div>
        </div>
      </section>
      <section className="servicio">
        <div className="container">
          <h2>{servicio.name}</h2>
          <p className="firm">{servicio.text}</p>
          <h3>¿Qué puedo ofrecerte?</h3>
          <ul>
            {servicio.services?.map((elemento) => (
              <li key={elemento.code}>
                <i className={elemento.icon}></i>
                <span className="text">{elemento.text}</span>
              </li>
            ))}
          </ul>
          <div dangerouslySetInnerHTML={{ __html: servicio.bigText }}></div>
          <h3>Cuéntame tu Requerimiento</h3>
          <p className="firm">Cuéntame tu idea y desarrollare tu proyecto para potenciar tu negocio.</p>
          <p><Link  class="button" to="/contactame">¡Contactame Ahora! <i class="fa-solid fa-arrow-right"></i></Link></p>
        </div>
      </section>
    </>
  );
};
