import React from "react";
import { Link } from "react-router-dom";
import "../portafolio/Portafolio.css";
import { trabajos } from "../../../data/trabajos";

export const Portafolio = () => {
  const proyectImages = require.context(
    "../../../assets/images/portafolio/",
    true
  );

  return (
    <>
      <section className="inicio-portafolio">
        <div className="imagen-background">
          <div className="slider-typography">
            <div className="slider-main">
              <h1>Portafolio</h1>
              <p>
                Conoce mi portafolio. Estos son algunos proyectos de los que he
                desarrollado recientemente. ¿Tienes algún proyecto o idea en
                mente?, comencemos a desarrollarla enviándome un correo
                electrónico a{" "}
                <a href="mailto:cristiancamiloalzateramirezweb@gmail.com">
                  cristiancamiloalzateramirezweb@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="portafolio">
        <div className="container">
          <div className="list-proyects">
            {trabajos.map((trabajo) => {
              return (
                <div className="proyect">
                  <div className="cover">
                    <Link to={"/proyecto/" + trabajo.id}>
                      <video 
                        autoplay="autoplay" loop="loop" muted="muted" src={proyectImages(`./${trabajo.id}.mp4`)} type="video/mp4"
                      ></video>
                    </Link>
                  </div>
                  <div className="info">
                    <span className="date">
                      Realizado por: {trabajo.creator} | {trabajo.date}
                    </span>
                    <Link to={"/proyecto/" + trabajo.id}>{trabajo.name}</Link>
                    <p>{trabajo.introduction}</p>
                    <Link className="button" to={"/proyecto/" + trabajo.id}>Más Información <i class="fa-solid fa-arrow-right"></i></Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};
